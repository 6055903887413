<template>
  <div>
    <div class="header-row">
      <filter-group/>
    </div>
    <div class="header-row">
      <!-- channel name filter-->
      <div class="select-option">
        <v-select
          v-model="channelFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          :placeholder="`${target} 검색`"
          :options="channelOptions"
        />
      </div>

      <!-- date filter-->
      <date-range-filter
        show-checkbox-options
        placeholder="신청일"
        :default-range="10"
      />

    </div>
  </div>
</template>
<script>

import { inject } from '@vue/composition-api'
import vSelect from 'vue-select'
import DateRangeFilter from '@/views/components/table/DateRangeFilter.vue'
import FilterGroup from '@/views/components/table/FilterGroup.vue'

export default {
  components: {
    vSelect,
    FilterGroup,
    DateRangeFilter,
  },
  props: {
    target: {
      type: String,
      default: '채널계정',
    },
  },
  setup() {
    const channelOptions = inject('channelOptions')
    const channelFilter = inject('channelFilter')

    return {
      channelOptions,
      channelFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~flatpickr/dist/flatpickr.css';
</style>
