<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <div v-else>
    <router-view :key="$route.path" />
    <app-table-view>
      <template #header>
        <account-list-header
          :target="type"
        />
      </template>
      <template #table>
        <account-list-table
          :target="type"
          :items="items"
        />
      </template>
    </app-table-view>
  </div>
</template>

<script>
import AppTableView from '@/views/layouts/AppTableView.vue'
import AccountListHeader from '@/views/components/table/headers/AccountListHeader.vue'
import AccountListTable from '@/views/components/table/tables/AccountListTable.vue'
import useAccountList from '@/views/utils/account/useAccountList'
import { adminRouteName } from '@/router/routes/admin'
import {
  onMounted, provide, ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useDate from '@/views/utils/useDate'

export default {
  components: {
    AppTableView,
    AccountListTable,
    AccountListHeader,
  },
  computed: {
    type: {
      get() {
        if (this.$route.name === adminRouteName.adAccount) {
          return '광고계정'
        }
        return '채널계정'
      },
    },
  },
  setup() {
    const {
      loading,
      error,
      items,
      fetchAdAccounts,
      fetchChannelAccounts,
    } = useAccountList()

    const { parseDateRange, getDateRangeFromToday, dateRangeConjunction } = useDate()
    const defaultDateRange = getDateRangeFromToday(30, dateRangeConjunction)
    const dateFilter = ref(defaultDateRange)
    // provide date filter data for DateRangeFilter component
    provide('dateFilter', dateFilter)
    provide('defaultDateRange', defaultDateRange)

    const { route } = useRouter()
    watch([route, dateFilter], () => {
      if (route.value.name === adminRouteName.channelAccount) {
        fetchChannelAccounts(...parseDateRange(dateFilter.value))
      } else {
        fetchAdAccounts(...parseDateRange(dateFilter.value))
      }
    })

    onMounted(() => {
      if (route.value.name === adminRouteName.channelAccount) {
        fetchChannelAccounts(...parseDateRange(dateFilter.value))
      } else {
        fetchAdAccounts(...parseDateRange(dateFilter.value))
      }
    })

    return {
      loading,
      error,
      items,
      fetchAdAccounts,
      fetchChannelAccounts,
    }
  },
}
</script>
