import {
  computed, ref, readonly, provide,
} from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'

export default function useAccountList() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useI18nUtils()
  const loading = ref(true)
  const error = ref(null)
  const currentAccounts = ref([])
  const currentPage = ref(1)
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const channelFilter = ref([])
  const statusFilter = ref(null)

  const fetchChannelAccounts = async (start, end) => {
    loading.value = true
    store.dispatch('admin/fetchChannelAccounts', {
      params: {
        start,
        end,
      },
    }).then(response => {
      const { accounts } = response.data.payload
      currentAccounts.value = accounts
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const fetchAdAccounts = async (start, end) => {
    loading.value = true
    store.dispatch('admin/fetchAdAccounts', {
      params: {
        start,
        end,
      },
    }).then(response => {
      const { accounts } = response.data.payload
      currentAccounts.value = accounts
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const statusOptions = computed(() => {
    const activated = currentAccounts.value.filter(account => account.status === 'ACTIVATED')
    const disabled = currentAccounts.value.filter(account => account.status === 'DISABLED')
    return [
      {
        text: `전체 현황 (${currentAccounts.value.length})`,
        value: null,
      },
      {
        text: `활성화 (${activated.length})`,
        value: 'ACTIVATED',
      },
      {
        text: `비활성화 (${disabled.length})`,
        value: 'DISABLED',
      },
    ]
  })

  const items = computed(() => {
    let data = currentAccounts.value
      .filter(account => account.status === (statusFilter.value || account.status))

    if (channelFilter.value.length > 0) {
      let result = []
      channelFilter.value.forEach(channelName => {
        result = [...result, ...data.filter(account => account.name === channelName)]
      })
      data = result
    }

    return data
  })

  const channelOptions = computed(() => {
    const channels = currentAccounts.value.map(account => account.name)
    return [...new Set(channels.flat())]
  })

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('statusFilter', statusFilter)
  provide('statusOptions', readonly(statusOptions))
  provide('channelOptions', readonly(channelOptions))
  provide('channelFilter', channelFilter)

  // for Table & Footer
  provide('items', readonly(items))
  provide('sortBy', readonly(sortBy))
  provide('isSortDirDesc', readonly(isSortDirDesc))
  provide('currentPage', currentPage)

  return {
    loading,
    error,
    items,
    fetchAdAccounts,
    fetchChannelAccounts,
  }
}
